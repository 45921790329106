/* eslint-disable react/no-danger */
import React from 'react';
import {
  CardContent,
  CardHeader,
  Button,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClaimEvidence } from '../../../../services/models/claim-evidence';
import { EvidencePanelProps } from '../../../../services/evidence-helpers';
import { EvidenceCard } from './evidence-panel.styles';
import useBreakpoint from '../../../../hooks/use-breakpoints';
import EvidenceReviewed from './evidence-reviewed';

interface CompleteEvidencePanelProps extends EvidencePanelProps {
  evidence: ClaimEvidence;
  onView: (evidence: ClaimEvidence) => unknown;
}

function CompleteEvidencePanel({
  title,
  descriptions,
  evidence,
  onView,
  ...props
} : CompleteEvidencePanelProps) {
  const { t } = useTranslation();
  const isWide = useBreakpoint('sm');

  return (
    <EvidenceCard {...props} complete>
      <CardHeader
        title={title}
        subheader={descriptions.map((description) => <div key={description} dangerouslySetInnerHTML={{ __html: description }} />)}
      />
      <CardContent>
        <Stack direction={isWide ? 'row' : 'column'} alignItems={isWide ? 'center' : 'flex-start'} gap={2} justifyContent="space-between">
          <EvidenceReviewed />
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => onView(evidence)}
            sx={{ backgroundColor: 'transparent' }}
          >
            {t('common.view')}
          </Button>
        </Stack>
      </CardContent>
    </EvidenceCard>
  );
}

export default CompleteEvidencePanel;
