import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';

export const ModalCard = styled(Card)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.common.white,
  padding: 0,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  maxWidth: 800,
  minWidth: 600,
}));

export const ModalCardHeader = styled(CardHeader)(({ theme }) => ({
  paddingTop: 0,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.common.background4}`,
  '& h2': {
    fontFamily: theme.typography.h2.fontFamily,
  },
})) as typeof CardHeader;

export const ModalCardContent = styled(CardContent)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(2),
  paddingBottom: 0,
}));

export const ModalCardActions = styled(CardActions)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  justifyContent: 'flex-end',
  borderTop: `1px solid ${theme.palette.common.background4}`,
}));

export const ModalCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

export default {};
