import { saveAs } from 'file-saver';
import http, { instance } from './http';
import { MyDocument } from './models/my-document';
import { EvidenceDocuments } from './models/evidence-documents';
import { EvidenceDocumentResultDto } from './models/evidence-document-result-dto';
import { FileResponseDto } from './models/file-response-dto';
import { EvidenceDocumentRequestDto } from './models/evidence-document-request-dto';
import { getFileExtensionFromContentDisposition } from '../routes/document/document.utils';
import { GenerateEvidenceDocumentWithSignatureDto } from './models/generate-evidence-document-with-signature-dto';

async function getMemberDocuments(): Promise<MyDocument[]> {
  return http.get('/api/v1/members/documents');
}

async function getDocument(documentId: string): Promise<any> {
  return instance.get(`/api/v1/documents/${documentId}`, { responseType: 'blob' });
}

async function downloadMemberDocument(downloadRequest: Promise<any>, documentId: string) {
  const response = await downloadRequest;
  const fileExt = getFileExtensionFromContentDisposition(response.headers['content-disposition']);
  const fileName = `${documentId}${fileExt}`;
  saveAs(response.data, fileName);
}

async function downloadDocument(downloadRequest: Promise<any>, fileName?: string) {
  try {
    const response = await downloadRequest;
    saveAs(response.data, fileName);
  } catch {
    throw new Error('Document download failed.');
  }
}

async function saveBase64AsFile(base64String: string, fileName: string, mimeType: string) {
  const byteCharacters = atob(base64String);
  const blob = new Blob([byteCharacters], { type: mimeType });
  saveAs(blob, fileName);
}

async function getGuideToClaimingDocument(claimId: string): Promise<FileResponseDto> {
  return instance.get(`/api/v1/bff/claims/${claimId}/documents/guideToClaiming`, { responseType: 'blob' });
}

async function uploadEvidenceDocument(claimId: string, evidenceId: string, data: EvidenceDocumentRequestDto): Promise<EvidenceDocumentResultDto> {
  return http.post(`/api/v1/bff/claims/${claimId}/evidences/${evidenceId}/documents`, data);
}

async function updateEvidenceDocuments(claimId: string, evidenceId: string, data: EvidenceDocuments): Promise<void> {
  return http.put(`/api/v1/members/claims/${claimId}/evidences/${evidenceId}/documents`, data);
}

async function signEvidenceDocument(
  evidenceId: string,
  data: GenerateEvidenceDocumentWithSignatureDto,
): Promise<void> {
  return http.post(`/api/v1/bff/evidences/${evidenceId}/documents/signed`, data);
}

async function getPreSignedUrl(documentNames: string[]): Promise<Record<string, string>> {
  return http.post('/api/v1/bff/presignedurls', documentNames);
}

async function uploadFileWithUrl(url: string, file: File) {
  return fetch(url, { method: 'PUT', headers: { 'Content-type': file.type }, body: file });
}

const documentApi = {
  downloadDocument,
  downloadMemberDocument,
  getDocument,
  getGuideToClaimingDocument,
  getPreSignedUrl,
  getMemberDocuments,
  saveBase64AsFile,
  signEvidenceDocument,
  uploadEvidenceDocument,
  updateEvidenceDocuments,
  uploadFileWithUrl,
};

export default documentApi;
