import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ClaimEvidence } from '../../../../services/models/claim-evidence';
import { EvidenceFulfillment } from '../../../../services/models/evidence-fulfillment';
import CompleteEvidencePanel from './complete-evidence-panel';
import SignEvidencePanel from './sign-evidence-panel';
import EmailEvidencePanel from './email-evidence-panel';
import ChaseEvidencePanel from './chase-evidence-panel';
import { ClaimEvidencePage } from '../../../pages';
import { MyClaim } from '../../../../services/models/my-claim';
import { EvidenceStatus } from '../../../../services/models/evidence-status';

function getEvidencePanel(evidence: ClaimEvidence, claim: MyClaim, action: () => unknown) {
  const complete = evidence.status === EvidenceStatus.Received;
  switch (evidence.evidenceFulfillment) {
    case EvidenceFulfillment.MemberSupplyOnly: return (
      <CompleteEvidencePanel
        evidence={evidence}
        complete={complete}
        onView={action}
        title={evidence.name ?? ''}
        descriptions={[]}
        sx={{ mb: 2 }}
      />
    );
    case EvidenceFulfillment.ApprovalOnly: return (
      <SignEvidencePanel
        evidence={evidence}
        complete={complete}
        onSign={action}
        title={evidence.name ?? ''}
        descriptions={[]}
        evidenceUploadProps={{
          evidence,
          claim,
        }}
        sx={{ mb: 2 }}
      />
    );
    case EvidenceFulfillment.Member:
    case EvidenceFulfillment.ThirdPartyViaMember:
      return (
        <EmailEvidencePanel
          evidence={evidence}
          complete={complete}
          onEmail={action}
          title={evidence.name ?? ''}
          descriptions={[]}
          evidenceUploadProps={{
            evidence,
            claim,
          }}
          sx={{ mb: 2 }}
        />
      );
    case EvidenceFulfillment.ThirdParty:
      return (
        <ChaseEvidencePanel
          title={evidence.name ?? ''}
          complete={complete}
          descriptions={[]}
          evidenceUploadProps={{
            evidence,
            claim,
          }}
          sx={{ mb: 2 }}
        />
      );
    default: return null;
  }
}

interface EvidencePanelProps {
  evidence: ClaimEvidence,
  claim: MyClaim,
}

function EvidencePanel({
  evidence,
  claim,
} : EvidencePanelProps) {
  const navigate = useNavigate();
  const handleReviewClick = () => {
    if (claim.claimId && evidence.id) {
      navigate(generatePath(ClaimEvidencePage.path, { claimId: claim.claimId, evidenceId: evidence.id }));
    }
  };

  return getEvidencePanel(evidence, claim, handleReviewClick);
}

export default EvidencePanel;
