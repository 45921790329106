import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HttpStatusCode, isAxiosError } from 'axios';
// eslint-disable-next-line import/no-cycle
import { AppDispatch, RootState } from '../store';
// eslint-disable-next-line import/no-cycle
import claimsApi from '../services/claims-api';
import { ClaimEvidence } from '../services/models/claim-evidence';
import { ClaimEvidenceFulfilmentGroups } from '../services/models/claim-evidence-fulfilment-groups';
import { MyClaim } from '../services/models/my-claim';
import { ClaimEvidences } from '../services/models/claim-evidences';

export const NONE = { status: 'none' } as const;
export const LOADING = { status: 'loading' } as const;
export const NOT_FOUND = { status: 'notFound' } as const;
export type AvailableClaimDetail = {
  status: 'available';
  claim: MyClaim;
  evidences: ClaimEvidence[];
  fulfilments: ClaimEvidenceFulfilmentGroups;
  completed: number;
  total: number;
};

export type ClaimDetailState =
  typeof NONE |
  typeof LOADING |
  typeof NOT_FOUND |
  AvailableClaimDetail;

export const CLAIM_DETAIL_SLICE_NAME = 'CLAIM_DETAIL';

/* eslint-disable no-param-reassign */
export const claimDetailSlice = createSlice({
  name: CLAIM_DETAIL_SLICE_NAME,
  initialState: NONE as ClaimDetailState,
  reducers: {
    reset() {
      return NONE;
    },
    startLoading() {
      return LOADING;
    },
    updated(state, { payload }: PayloadAction<ClaimDetailState>) {
      return payload;
    },
    evidenceUpdated(state, { payload }: PayloadAction<ClaimEvidences>) {
      if (state.status === 'available') {
        state.evidences = payload.evidences ?? [];
        state.completed = payload.completed ?? 0;
        state.total = payload.total ?? 0;
      }
    },
    fulfilmentsUpdated(state, { payload }: PayloadAction<ClaimEvidenceFulfilmentGroups>) {
      if (state.status === 'available') {
        state.fulfilments = payload;
      }
    },
  },
});
/* eslint-enable no-param-reassign */

export const {
  reset,
  startLoading,
  updated,
  evidenceUpdated,
  fulfilmentsUpdated,
} = claimDetailSlice.actions;

export const selectClaimDetailState = (state: RootState) => state.claimDetail;
export const selectAvailableClaimDetail = (state: RootState) => state.claimDetail as AvailableClaimDetail;

export const loadClaimDetail = (claimId: string) => async (dispatch: AppDispatch) => {
  dispatch(updated(LOADING));
  try {
    const claim = await claimsApi.getMemberClaim(claimId);
    const evidences = await claimsApi.getClaimEvidences(claimId);
    const fulfilments = await claimsApi.getClaimFulfilment(claimId);
    dispatch(updated({
      status: 'available',
      claim,
      evidences: evidences.evidences || [],
      fulfilments,
      total: evidences.total ?? 0,
      completed: evidences.completed ?? 0,
    }));
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === HttpStatusCode.NotFound) {
      dispatch(updated(NOT_FOUND));
    } else {
      dispatch(updated(NONE));
      throw e;
    }
  }
};

export const getClaimEvidences = (claimId: string) => async (dispatch: AppDispatch) => {
  const evidences = await claimsApi.getClaimEvidences(claimId);
  dispatch(evidenceUpdated(evidences));
};

export const getClaimFulfilments = (claimId: string) => async (dispatch: AppDispatch) => {
  const fulfilments = await claimsApi.getClaimFulfilment(claimId);
  dispatch(fulfilmentsUpdated(fulfilments));
};

export const updateEvidences = (claimId: string) => async (dispatch: AppDispatch) => {
  dispatch(getClaimEvidences(claimId));
  dispatch(getClaimFulfilments(claimId));
};
