// eslint-disable-next-line import/no-extraneous-dependencies
import * as Factory from 'factory.ts';
// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';
import { PreloadedState } from '@reduxjs/toolkit';
import { ClaimEvidence } from '../../services/models/claim-evidence';
import { AvailableClaimDetail } from '../../features/ClaimDetailSlice';
import { RootState } from '../../store';
import { EvidenceFulfillment } from '../../services/models/evidence-fulfillment';
import { Document } from '../../services/models/document';
// eslint-disable-next-line import/no-cycle
import { claim, claimFulfilment } from './claim-fixtures';
import { OptionDto } from '../../services/models/option-dto';
import { EvidenceDto } from '../../services/models/evidence-dto';
import { EvidenceStatus } from '../../services/models/evidence-status';

export function evidenceDocument(overrides?: Partial<Document>): Document {
  return {
    name: 'd5285a75-42d6-4217-a7d3-70b8cc71886c',
    id: 'd5285a75-42d6-4217-a7d3-70b8cc71886c',
    received: '2024-09-10T09:25:32.683+00:00',
    created: '2024-09-10T10:25:00+00:00',
    ...overrides,
  };
}

export const evidenceFactory = Factory.Sync.makeFactory<ClaimEvidence>({
  name: Factory.each((i) => `Evidence ${i.toString()}`),
  evidenceType: Factory.each((i) => `evidence_type_${i.toString()}`),
  status: Factory.each(() => faker.helpers.arrayElement(Object.values(EvidenceStatus))),
  id: Factory.each((i) => `evidence_id_${i.toString()}`),
  evidenceFulfillment: Factory.each(() => faker.helpers.arrayElement([
    EvidenceFulfillment.Member, EvidenceFulfillment.ApprovalOnly, EvidenceFulfillment.ThirdParty, EvidenceFulfillment.ThirdPartyViaMember,
  ])),
  documents: [],
});

export const defaultEvidence = evidenceFactory.build();

export function evidence(overrides?: Partial<ClaimEvidence>): ClaimEvidence {
  return {
    id: 'evidence_id_0',
    name: 'Your claim details',
    evidenceType: 'evidence_type_0',
    evidenceFulfillment: 'Member',
    status: EvidenceStatus.Requested,
    documents: [],
    ...overrides,
  };
}

export function unsolicitedEvidence(overrides?: Partial<EvidenceDto>): EvidenceDto {
  return {
    id: 'evidence_id_0',
    evidenceName: 'Your claim details',
    evidenceType: 'evidence_type_0',
    evidenceFulfillment: 'Member',
    documents: [],
    ...overrides,
  };
}

export function availableClaimDetail(overrides?: Partial<AvailableClaimDetail>): PreloadedState<RootState> {
  return {
    claimDetail: {
      status: 'available',
      claim: claim(),
      evidences: [],
      fulfilments: claimFulfilment({ completed: 0, total: 0, groups: [] }),
      completed: 0,
      total: overrides?.evidences?.length ?? 0,
      ...overrides,
    },
  };
}

export function categories(overrides: string[] = []): string[] {
  return [
    'Correspondence',
    'Financial',
    'Intervention',
    'Medical',
    ...overrides,
  ];
}

const defaultOption = {
  name: 'GP',
  text: 'GP Notes',
  aliases: [] as string[],
  categories: [
    'Medical',
  ] as string[],
  tags: [] as string[],
  attributes: {
    chaserDays: 14, AP_Remove: 'Yes', contactType: 'GP', fulfillment: 'ThirdParty', canGenerateDocument: true, Consolidate: 'Yes',
  },
} as const;

export function options(overrides: OptionDto[] = []): OptionDto[] {
  return [
    defaultOption,
    ...overrides,
  ];
}

export default {};
