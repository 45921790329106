import { evidenceFactory } from '../jest/evidence-fixtures';
import { ClaimEvidence } from '../../services/models/claim-evidence';
import { EvidenceFulfillment } from '../../services/models/evidence-fulfillment';
import { EvidenceStatus } from '../../services/models/evidence-status';

const mockEvidences = {
  MEMBER123: [
    evidenceFactory.build(),
  ],
  BCI0000R9R: evidenceFactory.buildList(10),
};

export const mockFixedEvidenceList = [
  {
    name: 'Evidence 0',
    evidenceType: 'evidence_type_0',
    status: EvidenceStatus.Requested,
    id: 'evidence_id_0',
    evidenceFulfillment: EvidenceFulfillment.Member,
    documents: [],
  },
  {
    name: 'Confidential medical certificate',
    evidenceType: 'evidence_type_1',
    status: EvidenceStatus.Waived,
    id: 'evidence_id_1',
    evidenceFulfillment: EvidenceFulfillment.MemberSupplyOnly,
    documents: [],
  },
  {
    name: 'Evidence 2',
    evidenceType: 'evidence_type_2',
    status: EvidenceStatus.Requested,
    id: 'evidence_id_2',
    evidenceFulfillment: EvidenceFulfillment.ApprovalOnly,
    documents: [],
  },
  {
    name: 'Evidence 3',
    evidenceType: 'evidence_type_3',
    status: EvidenceStatus.New,
    id: 'evidence_id_3',
    evidenceFulfillment: EvidenceFulfillment.Member,
    documents: [],
  },
  {
    name: 'Evidence 4',
    evidenceType: 'evidence_type_4',
    status: EvidenceStatus.Assessed,
    id: 'evidence_id_4',
    evidenceFulfillment: EvidenceFulfillment.ApprovalOnly,
    documents: [],
  },
  {
    name: 'Evidence 5',
    evidenceType: 'evidence_type_5',
    status: EvidenceStatus.Requested,
    id: 'evidence_id_5',
    evidenceFulfillment: EvidenceFulfillment.MemberSupplyOnly,
    documents: [],
  },
];

let documentIdCounter = 0;

type P8Document = {
  documentId: string,
  documentName: string,
  evidenceId: string
};

const p8Documents : Array<P8Document> = [];

export const addP8Document = (documentName: string, evidenceId: string) : number => {
  documentIdCounter += 1;
  p8Documents.push({
    documentId: documentIdCounter.toString(),
    documentName,
    evidenceId,
  });
  return documentIdCounter;
};

export const updateEvidenceDocuments = (evidenceId: string, documentIds: Array<string>) : void => {
  // @ts-ignore
  const data = mockEvidences[`${memberId}`] as ClaimEvidence[];
  const evidence = data.find((r) => r.id === evidenceId);
  const date = new Date();
  if (evidence) {
    evidence.documents = [];
    documentIds.forEach((documentId: string) => {
      const documentIndex = p8Documents.findIndex((r) => r.documentId.toString() === documentId.toString());
      if (documentIndex >= 0) {
        const p8Document = p8Documents[documentIndex];
        evidence.documents?.push({
          id: documentId,
          name: p8Document.documentName,
          created: date.toISOString(),
          received: date.toISOString(),
        });
      }
    });
  }
  // eslint-disable-next-line no-console
  console.log('all evidences', data);
};

export default mockEvidences;
