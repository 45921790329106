/* eslint-disable react/no-danger */
import React from 'react';
import {
  CardContent,
  CardHeader,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClaimEvidence } from '../../../../services/models/claim-evidence';
import { EvidencePanelProps } from '../../../../services/evidence-helpers';
import { EvidenceCard } from './evidence-panel.styles';
import DownloadDocumentButton from '../../../../components/download/download-document-button';
import EvidenceUpload, { EvidenceUploadProps } from '../evidence-upload/evidence-upload';
import useBreakpoint from '../../../../hooks/use-breakpoints';
import EvidenceReviewed from './evidence-reviewed';

interface EmailEvidencePanelProps extends EvidencePanelProps {
  evidence: ClaimEvidence;
  evidenceUploadProps: EvidenceUploadProps
  onEmail: (evidence: ClaimEvidence) => unknown;
}

function EmailEvidencePanel({
  title,
  descriptions,
  evidence,
  evidenceUploadProps,
  onEmail,
  ...props
} : EmailEvidencePanelProps) {
  const { t } = useTranslation();
  const isWide = useBreakpoint('sm');

  const downloadableDocument = evidence.documents?.[0];

  return (
    <EvidenceCard {...props}>
      <CardHeader
        title={title}
        subheader={descriptions.map((description) => <div key={description} dangerouslySetInnerHTML={{ __html: description }} />)}
      />
      <CardContent>
        <Stack gap={2} alignItems="flex-start">
          <Stack gap={2} direction={isWide ? 'row' : 'column'} sx={{ width: '100%' }}>
            <DownloadDocumentButton
              documentId={downloadableDocument?.id || ''}
              evidenceId={evidence.id}
              label={t('common.download')}
              fileName={downloadableDocument?.name || ''}
              errorMessage={t('components.evidencePanel.emailEvidencePanel.downloadError')}
              variant="contained"
              color="primary"
            />
          </Stack>
          <EvidenceUpload {...evidenceUploadProps} />
          {props.complete && <EvidenceReviewed />}
        </Stack>
      </CardContent>
    </EvidenceCard>
  );
}

export default EmailEvidencePanel;
