import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import { MyDocument } from '../../services/models/my-document';
import {
  DocumentCard as DocumentCardStyled,
  DocumentCardContent,
  DocumentCardHeader,
  DocumentCardActions,
} from './document-card.styles';
import useBusyState from '../../hooks/use-busy-state';
import documentApi from '../../services/document-api';
import DownloadIcon from '../../assets/icons/download-gold-icon.svg';

export interface DocumentCardProps {
  document: MyDocument
}

// eslint-disable-next-line react/function-component-definition
const DocumentCard = ({ document }: DocumentCardProps) => {
  const [loading, withLoadingState] = useBusyState();
  const handleClick = withLoadingState(async () => {
    try {
      if (document.documentId && document.documentName) {
        await documentApi.downloadDocument(documentApi.getDocument(document.documentId), document.documentName);
      } else {
        throw new Error('No document information');
      }
    } catch (e) {
      toast('Error downloading document', {
        position: 'bottom-left',
        type: 'error',
      });
    }
  });

  const { t } = useTranslation();
  return (
    <DocumentCardStyled onClick={handleClick} role="button">
      <DocumentCardHeader title={document.policyName} />
      <DocumentCardContent>
        <Typography variant="body1">
          {`${document.policyId} - ${document.documentName}`}
        </Typography>
      </DocumentCardContent>
      <DocumentCardActions>
        {loading ? <CircularProgress size={20} /> : <img src={DownloadIcon} width={16} alt={t('common.download')!} />}
      </DocumentCardActions>
    </DocumentCardStyled>
  );
};

export default DocumentCard;
