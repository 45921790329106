/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import {
  CardContent,
  CardHeader,
  Button,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClaimEvidence } from '../../../../services/models/claim-evidence';
import { EvidencePanelProps } from '../../../../services/evidence-helpers';
import { EvidenceCard } from './evidence-panel.styles';
import EvidenceUpload, { EvidenceUploadProps } from '../evidence-upload/evidence-upload';
import EvidenceReviewed from './evidence-reviewed';

enum ReviewType {
  SIGN = 'sign',
  UPLOAD = 'upload',
}

interface SignEvidencePanelProps extends EvidencePanelProps {
  evidence: ClaimEvidence;
  evidenceUploadProps: EvidenceUploadProps;
  onSign: (evidence: ClaimEvidence) => unknown;
}

function SignEvidencePanel({
  title,
  descriptions,
  evidence,
  evidenceUploadProps,
  onSign,
  ...props
} : SignEvidencePanelProps) {
  const { t } = useTranslation();
  const [reviewType, setReviewType] = useState<ReviewType>(ReviewType.SIGN);

  return (
    <EvidenceCard {...props}>
      <CardHeader
        title={title}
        subheader={descriptions.map((description) => <div key={description} dangerouslySetInnerHTML={{ __html: description }} />)}
      />
      <CardContent>
        <Stack gap={2} alignItems="flex-start">
          <RadioGroup
            row
            name="reviewType"
            value={reviewType}
            onChange={(event, value) => setReviewType(value as ReviewType)}
          >
            <FormControlLabel value={ReviewType.SIGN} control={<Radio />} label={t('components.evidencePanel.signEvidence.sign')} />
            <FormControlLabel value={ReviewType.UPLOAD} control={<Radio />} label={t('components.evidencePanel.signEvidence.upload')} />
          </RadioGroup>
          {reviewType === ReviewType.SIGN && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSign(evidence)}
            >
              {t('components.evidencePanel.signEvidence.reviewSign')}
            </Button>
          )}
          {reviewType === ReviewType.UPLOAD && (
            <EvidenceUpload {...evidenceUploadProps} />
          )}
          {props.complete && <EvidenceReviewed />}
        </Stack>
      </CardContent>
    </EvidenceCard>
  );
}

export default SignEvidencePanel;
