// eslint-disable-next-line import/no-extraneous-dependencies
import * as Factory from 'factory.ts';
// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';
import { PreloadedState } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../store';
// eslint-disable-next-line import/no-cycle
import { evidence, evidenceDocument } from './evidence-fixtures';
import { AvailableMember } from '../../features/MemberSlice';
import { MyClaim } from '../../services/models/my-claim';
import { ClaimIncapacityType, ClaimStatus } from '../../services/claims-helpers';
import { member } from './member-fixtures';
import { ClaimEvidenceFulfilmentGroups } from '../../services/models/claim-evidence-fulfilment-groups';

export const claimFactory = Factory.Sync.makeFactory<MyClaim>({
  claimId: Factory.each((i) => `00000000${i.toString()}`),
  incapacity: Factory.each(() => faker.helpers.enumValue(ClaimIncapacityType)),
  assessmentStatus: Factory.each(() => faker.helpers.enumValue(ClaimStatus)),
  firstNotificationOfLoss: Factory.Sync.each(() => new Date().toISOString()),
  policyName: Factory.each(() => faker.helpers.arrayElement(['Income First', 'Income One Plus', 'Income One', 'Pure Protection', 'Pure protection Plus'])),
  policyId: Factory.each((i) => `policy_id_${i.toString()}`),
  tag: 'addNewSection',
  branch: 'claims',
});

export const defaultClaim = claimFactory.build({ assessmentStatus: ClaimStatus.Notification.toString() });

export function claim(overrides?: Partial<MyClaim>) {
  return {
    claimId: '000000001',
    incapacity: ClaimIncapacityType.Illness,
    assessmentStatus: ClaimStatus.Assessment,
    firstNotificationOfLoss: '2024-08-15T10:12:54+00:00',
    policyName: 'Income First',
    policyId: 'policy_id_1',
    tag: 'addNewSection',
    branch: 'claims',
    ...overrides,
  };
}

export function availableMember(claims?: MyClaim[], overrides?: Partial<AvailableMember>): PreloadedState<RootState> {
  return {
    member: {
      status: 'available',
      documents: [],
      member: member(),
      ...overrides,
    },
  };
}

export function claimFulfilment(overrides?: Partial<ClaimEvidenceFulfilmentGroups>): ClaimEvidenceFulfilmentGroups {
  return {
    total: 2,
    completed: 0,
    groups: [
      {
        fulfilmentType: 'Member',
        text: 'Please review these documents',
        total: 0,
        completed: 0,
        evidences: [],
      },
      {
        fulfilmentType: 'ApprovalOnly',
        text: 'Please review and sign this document',
        total: 1,
        completed: 0,
        evidences: [
          evidence({
            evidenceFulfillment: 'ApprovalOnly',
            name: 'Declaration and Consent',
            evidenceType: 'CONSENT',
            status: 'Requested',
            documents: [],
          }),
        ],
      },
      {
        fulfilmentType: 'Member or ThirdPartyViaMember',
        text: 'Please upload these document(s)',
        total: 1,
        completed: 0,
        evidences: [
          evidence({
            evidenceFulfillment: 'ApprovalOnly',
            name: 'GP Report',
            evidenceType: 'GPR',
            status: 'Requested',
            documents: [evidenceDocument()],
          }),
        ],
      },
      {
        fulfilmentType: 'ThirdParty',
        text: 'What we\'re working on',
        total: 0,
        completed: 0,
        evidences: [],
      },
    ],
    ...overrides,
  };
}

export default {};
