// eslint-disable-next-line import/no-extraneous-dependencies
import * as Factory from 'factory.ts';
// eslint-disable-next-line import/no-extraneous-dependencies
import { faker } from '@faker-js/faker';
import { PreloadedState } from '@reduxjs/toolkit';
import { MyDocument } from '../../services/models/my-document';
import { AvailableMember } from '../../features/MemberSlice';
import { RootState } from '../../store';
import { member } from './member-fixtures';

export const documentFactory = Factory.Sync.makeFactory<MyDocument>({
  documentId: Factory.each((i) => `DOC_${i.toString()}`),
  documentName: Factory.each((i) => `Test Document #${i.toString()}`),
  policyId: Factory.each((i) => `POL_${i.toString()}`),
  policyName: Factory.each((i) => `Test Policy #${i.toString()}`),
  documentType: Factory.each(() => faker.helpers.arrayElement(['Application', 'Policy'])),
});

export const defaultDocument = documentFactory.build();

export function document(overrides?: Partial<MyDocument>): MyDocument {
  return {
    documentId: 'D1',
    documentName: 'Test Document',
    documentRef: 'Dref',
    documentType: 'Policy',
    policyId: 'P1',
    policyName: 'Test Policy',
    ...overrides,
  };
}

export function availableMember(documents: MyDocument[] = [], overrides?: Partial<AvailableMember>): PreloadedState<RootState> {
  return {
    member: {
      status: 'available',
      documents,
      member: member(),
      ...overrides,
    },
  };
}

export default {};
